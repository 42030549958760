
import { defineComponent, watch, computed } from 'vue';
import usePage from '@/composable/usePage';
import VMarkdownContent from '@/components/sections/VMarkdownContent/VMarkdownContent.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
	components: {
		VMarkdownContent,
	},
	setup() {
		const route = useRoute();

		const getName = () => String(route.name);
		const { page, changePage } = usePage(getName());

		const pageName = computed(() => getName());

		watch(pageName, () => {
			console.log(pageName.value);
			changePage(pageName.value);
		});

		return { page };
	},
});
