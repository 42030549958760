
import { defineComponent, toRefs } from 'vue';
import Markdown from '@/plugins/markdown.vue';
import { Section } from '@/interfaces';
import useContactForm from '@/composable/useContactForm';
import VInput from '@/components/general/VInput/VInput.vue';
import VSelect from '@/components/general/VSelect/VSelect.vue';

export default defineComponent({
	components: {
		Markdown,
		VInput,
		VSelect,
	},
	props: {
		section: {
			type: Object as () => Section,
			required: true,
		},
	},
	setup() {
		const { state, v$, submit } = useContactForm();
		const subjects = [
			'Allround horeca medewerker',
			'Basis solar installateur',
			'Bedieningsassistent',
			'Basiskok (Cookcamp)',
			'Gastheer/-vrouw',
			'Keukenassistent',
			'Scrum',
			'Zelfstandig werkend kok',
			'Samenwerking', 'Werken bij KRM academy', 'Overige',
		];

		return { subjects, ...toRefs(state), v$, submit };
	},
});
