<template>
	<section id="about" class="about-area pt-120 pb-60">
		<div class="container">
			<div class="row flex-row-reverse">
				<div class="col-lg-6">
					<div class="about-img mb-60">
						<img :src="section.image" width="615" height="476" alt="about image">
						<div v-if="section.aboutOverlay" class="about-overlay-box" @click="goToUrl(section.aboutOverlayLink)">
							<h4 class="wow flipInY">
								{{ section.aboutOverlay }}
							</h4>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="about-content-box mb-60">
						<Markdown :text="section.content" />
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Markdown from '@/plugins/markdown.vue';
import { Section } from '@/interfaces';
import { useRouter } from 'vue-router';

export default defineComponent({
	components: {
		Markdown,
	},
	props: {
		section: {
			type: Object as () => Section,
			required: true,
		},
	},
	setup() {
		const router = useRouter();
		const goToUrl = (url: string) => {
			if (url.includes('http')) {
				window.location.href = url;
				return;
			}
			router.push(url);
		};
		return { goToUrl };
	},
});
</script>

<style lang="scss" src="./VAboutReverse.scss"></style>
