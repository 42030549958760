import { createApp } from 'vue';

import App from '@/App.vue';
import router from '@/router';
import store, { key } from '@/store';
import { PrimeVueSetup } from '@/plugins/PrimeVue';

import VHero from '@/components/sections/VHero/VHero.vue';
import VAbout from '@/components/sections/VAbout/VAbout.vue';
import VAboutReverse from '@/components/sections/VAboutReverse/VAboutReverse.vue';
import VService from '@/components/sections/VService/VService.vue';
import VFeatures from '@/components/sections/VFeatures/VFeatures.vue';
import VFaq from '@/components/sections/VFaq/VFaq.vue';
import VCta from '@/components/sections/VCta/VCta.vue';
import VTestimonial from '@/components/sections/VTestimonial/VTestimonial.vue';
import VContact from '@/components/sections/VContact/VContact.vue';
import VMap from '@/components/sections/VMap/VMap.vue';
import VTeam from '@/components/sections/VTeam/VTeam.vue';
import VSponsor from '@/components/sections/VSponsor/VSponsor.vue';

const app = createApp(App);
app.use(router);
app.use(store, key);
PrimeVueSetup(app);
app.component('VHero', VHero);
app.component('VAbout', VAbout);
app.component('VAboutReverse', VAboutReverse);
app.component('VService', VService);
app.component('VFeatures', VFeatures);
app.component('VFaq', VFaq);
app.component('VCta', VCta);
app.component('VTestimonial', VTestimonial);
app.component('VContact', VContact);
app.component('VMap', VMap);
app.component('VTeam', VTeam);
app.component('VSponsor', VSponsor);
app.mount('#app');
