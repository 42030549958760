import { render } from "./VContact.vue?vue&type=template&id=17181b6a"
import script from "./VContact.vue?vue&type=script&lang=ts"
export * from "./VContact.vue?vue&type=script&lang=ts"

import "./VContact.scss?vue&type=style&index=0&lang=scss"
const cssModules = script.__cssModules = {}
import style1 from "./VContact.vue?vue&type=style&index=1&id=17181b6a&lang=scss&module=true"
cssModules["$style"] = style1
script.render = render

export default script