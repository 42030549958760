<template>
	<section class="contact-area-section light-bg pt-120 pb-60">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-8">
					<div class="section-title text-center mb-60">
						<span class="span">Contact en/of aanmelden?</span>
						<h2>Laten we kennismaken!</h2>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="contact-form was-validated">
						<form @submit.prevent="submit">
							<div class="row">
								<div class="col-lg-6">
									<VInput
										v-model="name"
										:error="v$.name.$errors[0]?.$message"
										type="text"
										name="name"
										placeholder="Naam"
										icon="fas fa-user"
										required
									/>
								</div>
								<div class="col-lg-6">
									<VInput
										v-model="email"
										:error="v$.email.$errors[0]?.$message"
										type="text"
										name="email"
										placeholder="E-mail"
										icon="fas fa-envelope"
										required
									/>
								</div>
								<div class="col-lg-6">
									<VInput
										v-model="phone"
										:error="v$.phone.$errors[0]?.$message"
										type="text"
										name="phone"
										placeholder="Telefoonnummer"
										icon="fas fa-phone-alt"
									/>
								</div>
								<div class="col-lg-6">
									<VSelect
										v-model="subject"
										:options="subjects"
										:error="v$.subject.$errors[0]?.$message"
										placeholder="Onderwerp"
									/>
								</div>
								<div class="col-lg-12">
									<VInput
										v-model="message"
										:error="v$.message.$errors[0]?.$message"
										type="textarea"
										name="message"
										placeholder="Bericht"
										required
									/>
								</div>
								<div class="col-lg-12">
									<div class="form_group text-center">
										<button class="main-btn" type="submit">Verzend bericht</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div v-if="section.content" class="contact-info-wrapper">
				<Markdown :text="section.content" />
			</div>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import Markdown from '@/plugins/markdown.vue';
import { Section } from '@/interfaces';
import useContactForm from '@/composable/useContactForm';
import VInput from '@/components/general/VInput/VInput.vue';
import VSelect from '@/components/general/VSelect/VSelect.vue';

export default defineComponent({
	components: {
		Markdown,
		VInput,
		VSelect,
	},
	props: {
		section: {
			type: Object as () => Section,
			required: true,
		},
	},
	setup() {
		const { state, v$, submit } = useContactForm();
		const subjects = [
			'Allround horeca medewerker',
			'Basis solar installateur',
			'Bedieningsassistent',
			'Basiskok (Cookcamp)',
			'Gastheer/-vrouw',
			'Keukenassistent',
			'Scrum',
			'Zelfstandig werkend kok',
			'Samenwerking', 'Werken bij KRM academy', 'Overige',
		];

		return { subjects, ...toRefs(state), v$, submit };
	},
});
</script>

<style lang="scss" src="./VContact.scss"></style>
<style lang="scss" module>
.error {
	display: block;
	position: absolute;
	bottom: -25px;
	padding: 0 20px;
}
</style>
