<template>
	<header class="header-area header-area-v1">
		<div class="header-top">
			<div class="container-fluid">
				<div class="row">
					<div class="col-lg-8">
						<div class="top-left">
							<ul>
								<li>
									<span>
										<i class="fas fa-envelope"></i>
										<a :href="`mailto:${contactMail}`">{{ contactMail }}</a>
									</span>
								</li>
								<li>
									<span>
										<i class="fas fa-phone-alt"></i>
										<a :href="`tel:${phoneNumber}`">{{ phoneNumber }}</a>
									</span>
								</li>
								<li>
									<span>
										<i class="fas fa-map-marker-alt"></i>
										{{ location }}
									</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="top-right">
							<ul class="social-link">
								<li v-for="profile in profiles" :key="profile.name">
									<a :href="profile.href">
										<i :class="profile.icon" class="fab"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div :class="{ sticky: scrollTop >= 190, 'breakpoint-on': width <= 1000 }" class="header-navigation">
			<div class="container-fluid">
				<div class="row align-items-center">
					<div class="col-xl-3 col-lg-3 col-md-6 col-8">
						<div class="site-branding">
							<div class="brand-logo">
								<router-link :class="$style.logoWrapper" to="/">
									<img :src="logo" :class="$style.logo" width="250" height="90" alt="logo" />
								</router-link>
							</div>
						</div>
					</div>
					<div class="col-xl-7 col-lg-6 col-md-4 col-3">
						<div :class="{ 'menu-on': isNavbarOpen }" ref="navbar" class="nav-menu">
							<!-- Mobile Menu Logo -->
							<div class="site-branding text-center ml-2 mr-2 mb-4 d-none">
								<div class="brand-logo">
									<router-link to="/">
										<img :src="logo" width="200" height="40" alt="logo" />
									</router-link>
								</div>
							</div>
							<!-- navbar-close -->
							<div class="navbar-close" @click="closeNavbar">
								<div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
							</div>
							<!-- main menu -->
							<nav class="main-menu">
								<ul>
									<li class="menu-item">
										<router-link to="/" @click="goTo($event, '/')">Home</router-link>
									</li>
									<li class="menu-item">
										<router-link to="/over-ons" @click="goTo($event, '/over-ons')">Over ons</router-link>
									</li>
									<li class="menu-item menu-item-has-children">
										<router-link
											to="/onze-opleidingen"
											@click="goTo($event, '/onze-opleidingen')">Onze opleidingen</router-link>
										<ul class="sub-menu">
											<li>
												<router-link
													to="/onze-opleidingen/allround-horeca-medewerker"
													@click="goTo($event, '/onze-opleidingen/allround-horeca-medewerker')"
												>
													Allround horeca medewerker
												</router-link>
											</li>
											<li>
												<router-link
													to="/onze-opleidingen/basis-solar-installateur"
													@click="goTo($event, '/onze-opleidingen/basis-solar-installateur')"
												>
													Basis solar installateur
												</router-link>
											</li>
											<li>
												<router-link
													to="/onze-opleidingen/basiskok"
													@click="goTo($event, '/onze-opleidingen/basiskok')"
												>
													Basiskok (Cookcamp)
												</router-link>
											</li>
											<li>
												<router-link
													to="/onze-opleidingen/Bedieningsassistent"
													@click="goTo($event, '/onze-opleidingen/Bedieningsassistent')"
												>
													Bedieningsassistent
												</router-link>
											</li>
											<li>
												<router-link
													to="/onze-opleidingen/gastheer-gastvrouw"
													@click="goTo($event, '/onze-opleidingen/gastheer-gastvrouw')"
												>
													Gastheer/-vrouw
												</router-link>
											</li>
											<li>
												<router-link
													to="/onze-opleidingen/keukenassistent"
													@click="goTo($event, '/onze-opleidingen/keukenassistent')"
												>
													Keukenassistent
												</router-link>
											</li>
											<li>
												<router-link
													to="/onze-opleidingen/scrum"
													@click="goTo($event, '/onze-opleidingen/scrum')"
												>
													Scrum
												</router-link>
											</li>
											<li>
												<router-link
													to="/onze-opleidingen/turbo-keukenassistent"
													@click="goTo($event, '/onze-opleidingen/turbo-keukenassistent')"
												>
													Turbo KAS (keukenassistent)
												</router-link>
											</li>
											<li>
												<router-link
													to="/onze-opleidingen/zelfstandig-werkend-kok"
													@click="goTo($event, '/onze-opleidingen/zelfstandig-werkend-kok')"
												>
													Zelfstandig werkend kok
												</router-link>
											</li>
										</ul>
									</li>
									<li class="menu-item">
										<router-link to="/zakelijk" @click="goTo($event, '/zakelijk')">Zakelijk</router-link>
									</li>
									<li class="menu-item">
										<router-link to="/contact" @click="goTo($event, '/contact')">Contact</router-link>
									</li>
								</ul>
							</nav>
						</div>
						<!-- navbar toggler -->
						<div :class="{ active: isNavbarOpen }" class="navbar-toggler float-right" @click="toggleNavbar">
							<span></span><span></span><span></span>
						</div>
					</div>
					<div class="col-lg-2 col-md-2 col-1">
						<div class="nav-right">
							<ul class="float-right">
								<li><router-link
									to="/contact" class="main-btn"
									@click="goTo($event, '/contact')"
								>Aanmelden</router-link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useHeader from '@/composable/useHeader';

export default defineComponent({
	props: {
		logo: {
			type: String,
			required: true,
		},
		contactMail: {
			type: String,
			required: true,
		},
		phoneNumber: {
			type: String,
			required: true,
		},
		location: {
			type: String,
			required: true,
		},
		profiles: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return useHeader();
	},
});
</script>

<style lang="scss" src="./TheHeader.scss"></style>
<style lang="scss" module>
.logoWrapper {
	width: 100%;
}

.logo {
	width: 100%;
	max-height: 50px;

	@media #{$xl} {
		max-height: 80px;
	}
}
</style>
