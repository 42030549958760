
import { defineComponent } from 'vue';
import Markdown from '@/plugins/markdown.vue';
import { Section } from '@/interfaces';

export default defineComponent({
	components: {
		Markdown,
	},
	props: {
		section: {
			type: Object as () => Section,
			required: true,
		},
	},
	mounted() {
		// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
		// @ts-ignore
		// eslint-disable-next-line no-undef
		$('.team-slider-one').slick({
			dots: false,
			arrows: false,
			infinite: true,
			autoplay: true,
			Speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});
	},
});
