
import { defineComponent } from 'vue';
import Markdown from '@/plugins/markdown.vue';
import { Section } from '@/interfaces';
import { useRouter } from 'vue-router';

export default defineComponent({
	components: {
		Markdown,
	},
	props: {
		section: {
			type: Object as () => Section,
			required: true,
		},
	},
	setup() {
		const router = useRouter();
		const goToUrl = (url: string) => {
			if (url.includes('http')) {
				window.location.href = url;
				return;
			}
			router.push(url);
		};
		return { goToUrl };
	},
});
