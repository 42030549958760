import { onMounted, computed, ref } from 'vue';
import { useStore } from '@/store';
import { PageActions } from '@/store/modules/page';

export default function usePage(name: string) {
	const store = useStore();
	const pageName = ref(name);
	const page = computed(() => store.getters.page(pageName.value));

	const changePage = (newPageName: string) => {
		store.dispatch(PageActions.getSingle, newPageName);
		pageName.value = newPageName;
	};

	onMounted(() => {
		store.dispatch(PageActions.getSingle, name);
	});

	return { page, changePage };
}
