
import { defineComponent } from 'vue';
import { useVModels } from '@vueuse/core';
import { Dropdown } from '@/plugins/PrimeVue';

export default defineComponent({
	components: {
		Dropdown,
	},
	props: {
		modelValue: {
			type: String,
			required: true,
		},
		placeholder: {
			type: String,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
		error: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		const { modelValue } = useVModels(props);
		return { value: modelValue };
	},
});
