<template>
	<footer class="footer footer-v2 bg_cover">
		<div class="container">
			<div class="footer-widget pb-40 pt-40">
				<div class="row">
					<div class="footer-logo col-lg-4 col-md-6 col-sm-12">
						<div class="center-logo">
							<router-link to="">
								<img :src="logo" width="182" height="75" alt="logo" />
							</router-link>
						</div>
					</div>
					<div class="footer-text col-lg-3 col-md-6 col-sm-12">
					</div>
					<div class="col-lg-5 col-md-6 col-sm-12">
						<ul class="usefull-links-list">
							<li class="svh-logo ml-10">
								<img src="/static/img/footer/svh.svg" width="83" height="83" alt="svh" />
							</li>
							<li class="ml-10 mr-10">
								<img src="static/img/footer/s-bb.svg" width="75" height="75" alt="s-bb" />
							</li>
							<li>
								<a href="https://krm.nl" target="_blank">
									<img src="/static/img/footer/krm.svg" width="75" height="75" alt="krm" />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="copyright-area">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-lg-6 col-md-6 col-sm-12">
						<div class="footer-social">
							<ul class="social-link">
								<li v-for="profile in profiles" :key="profile.name">
									<a :href="profile.href">
										<i :class="profile.icon" class="fab"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-6 col-md-6 col-sm-12">
						<div class="copyright-text">
							<p>{{ copyRight }} - <router-link :to="{ name: 'privacy' }">Privacy Statement</router-link></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<cookie-law
			buttonText="OK!"
			message="
				Deze website maakt gebruik van cookies om ervoor te zorgen dat u de beste ervaring op onze website krijgt.
			"
		/>
	</footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import CookieLaw from '@/components/CookieLaw/CookieLaw.vue';

export default defineComponent({
	components: {
		CookieLaw,
	},
	props: {
		logo: {
			type: String,
			required: true,
		},
		contactMail: {
			type: String,
			required: true,
		},
		phoneNumber: {
			type: String,
			required: true,
		},
		location: {
			type: String,
			required: true,
		},
		profiles: {
			type: Object,
			required: true,
		},
		copyRight: {
			type: String,
			required: true,
		},
	},
});
</script>

<style lang="scss" src="./TheFooter.scss"></style>
